body {

	min-width: 360px;
	padding-top: 30px;
	background-color: $color-primary;
	background-image: url("/_/images/layout/body.png");
	
	&:not(.page_front) {
			
		@include media-breakpoint-up(md) {

			height: 100%;
			padding-bottom: 48px;

			.site_info {
				position: absolute;
				bottom: 0;
				width: 100%;
			}

		}
		
	}


}
