.branding-title {
	margin: 0;
	font-weight: normal;
	line-height: 1em;
}

.logo {
	display: inline-block;
	margin: 0 auto;
	@include font-size(42);
	font-family: $font-family-headings;
	font-weight: $heading-weight-normal;
	line-height: 1em;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	color: theme(secondary, dark) !important;
	small {
		display: block;
		padding: 6px 20px 5px;
		font-family: $font-family-body;
		font-weight: $body-weight-bold;
		@include font-size(18);
		line-height: 1em;
		letter-spacing: 4px;
		background-color: theme(primary, base);
		color: $white;
	}
}