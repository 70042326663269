.block {
	padding: 0;
	.container {
		padding: 30px;
		background-color: rgba(white,0.92);
		border-left: 3px solid #d5d5d5;
		border-right: 3px solid #d5d5d5;
	}
	@include media-breakpoint-up(md) {
		.container { padding: 30px 45px; }
	}
}

.block_banner .container { border-top: 3px solid #d5d5d5; }
.block_main .container { border-bottom: 3px solid #d5d5d5; }

.block_brands {
	line-height: 0;
	text-align: center;
	border-top: 3px solid #d5d5d5;
	border-bottom: 3px solid #d5d5d5;
	background-color: theme(secondary, dark);
	box-shadow: 0 3px 4px 2px rgba(black,0.3);
	.container {
		padding: 15px 15px 0;
		border-right: none;
		border-left: none;
		background-color: theme(secondary, dark);
		background-image: linear-gradient(
			to right,
			theme(secondary, dark),
			$color-secondary,
			theme(secondary, dark)
		);
	}
	@include media-breakpoint-only(md) {
		.row { justify-content: center; }
		.wrap { @include make-col(8); }
	}
	@include media-breakpoint-up(md) {
		.container { padding: 15px 0 0; }
	}
	@include media-breakpoint-up(lg) {
		.container { padding: 15px 0; }
	}
}

	.list-brands {
		li {
			display: inline-block;
			width: 100px;
			height: 100px;
			b { visibility: hidden; }
			background-image: url("/_/images/brands/brands_sprite.png");
			background-size: 500px 100px;
			background-repeat: no-repeat;
			&:nth-child(2) { background-position-x: -100px; }
			&:nth-child(3) { background-position-x: -200px; }
			&:nth-child(4) { background-position-x: -300px; }
			&:nth-child(5) { background-position-x: -400px; }
			&:nth-child(6) { background-position-x: -500px; }
			&:nth-child(7) { background-position-x: -600px; }
		}
		@include media-breakpoint-up(lg) {
			li:not(:last-child) { margin-right: 10px; }
		}
		@include media-breakpoint-up(xl) {
			li:not(:last-child) { margin-right: 30px; }
		}
	}

