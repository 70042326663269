.site_info {

	margin-top: 30px;
	padding: 15px;
	border-top: 5px solid white;
	background-color: theme(secondary, dark);
	background-image: linear-gradient(to right,
		theme(secondary, dark),
		$color-secondary,
		theme(secondary, dark));
	color: $white;
	
	.container {
		background-color: transparent;
		border-right: none;
		border-left: none;
	}

	a { color: $white; text-decoration: none; }
	.list > li:not(:last-child) { margin-bottom: 4px; }

	@include media-breakpoint-up(md) {
		text-align: center;
		.list > li { display: inline-block; margin: 0 15px 0 0 !important;}
	}

}
