// ===========================================
// BOOTSTRAP'S GRID
// ===========================================

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);
@include _assert-ascending($container-max-widths, "$container-max-widths");

.container {
	@include make-container();
	@include make-container-max-widths();
}

.container-fluid {
	width: 100%;
	@include make-container();
}

.row {
	@include make-row();
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
	> .wrap {
		padding-right: 0;
		padding-left: 0;
	}
}


// ===========================================
// BACE GRID
// ===========================================

html {
	@include prefix('box-sizing', 'border-box', webkit moz ms o);
    -ms-overflow-style: scrollbar;
}

	*,
	*:before,
	*:after {
		@include prefix('box-sizing', 'inherit', webkit moz ms o)	
	}

	.wrap { @include make-col-ready() }

	.flex_container {
		display: flex;
		flex-wrap: wrap;
		margin: 0 0 1em;
		box-sizing: border-box;
		[class*="col-"] {
			position: relative;
			width: 100%;
			min-height: 1px;
			padding: 0 15px;
		}
		&.gutter-fix [class*="col-"] { padding-left: 0 !important; }
		@include media-breakpoint-up(sm) {
			.col-sm-12 { width: percentage(12/12); }
			.col-sm-11 { width: percentage(11/12); }
			.col-sm-10 { width: percentage(10/12); }
			.col-sm-9 { width: percentage(9/12); }
			.col-sm-8 { width: percentage(8/12); }
			.col-sm-7 { width: percentage(7/12); }
			.col-sm-6 { width: percentage(6/12); }
			.col-sm-5 { width: percentage(5/12); }
			.col-sm-4 { width: percentage(4/12); }
			.col-sm-3 { width: percentage(3/12); }
			.col-sm-2 { width: percentage(2/12); }
			.col-sm-1 { width: percentage(1/12); }
		}

		@include media-breakpoint-up(md) {
			.col-md-12 { width: percentage(12/12); }
			.col-md-11 { width: percentage(11/12); }
			.col-md-10 { width: percentage(10/12); }
			.col-md-9 { width: percentage(9/12); }
			.col-md-8 { width: percentage(8/12); }
			.col-md-7 { width: percentage(7/12); }
			.col-md-6 { width: percentage(6/12); }
			.col-md-5 { width: percentage(5/12); }
			.col-md-4 { width: percentage(4/12); }
			.col-md-3 { width: percentage(3/12); }
			.col-md-2 { width: percentage(2/12); }
			.col-md-1 { width: percentage(1/12); }
		}
			@include media-breakpoint-only(md) {
				.col-md-break { width: percentage(12/12); }
			}

		@include media-breakpoint-up(lg) {
			.col-lg-12 { width: percentage(12/12); }
			.col-lg-11 { width: percentage(11/12); }
			.col-lg-10 { width: percentage(10/12); }
			.col-lg-9 { width: percentage(9/12); }
			.col-lg-8 { width: percentage(8/12); }
			.col-lg-7 { width: percentage(7/12); }
			.col-lg-6 { width: percentage(6/12); }
			.col-lg-5 { width: percentage(5/12); }
			.col-lg-4 { width: percentage(4/12); }
			.col-lg-3 { width: percentage(3/12); }
			.col-lg-2 { width: percentage(2/12); }
			.col-lg-1 { width: percentage(1/12); }
		}	
			@include media-breakpoint-only(lg) {
				.col-lg-break { width: percentage(12/12); }
			}

		@include media-breakpoint-up(xl) {
			.col-xl-break { width: percentage(12/12); }
			.col-xl-12 { width: percentage(12/12); }
			.col-xl-11 { width: percentage(11/12); }
			.col-xl-10 { width: percentage(10/12); }
			.col-xl-9 { width: percentage(9/12); }
			.col-xl-8 { width: percentage(8/12); }
			.col-xl-7 { width: percentage(7/12); }
			.col-xl-6 { width: percentage(6/12); }
			.col-xl-5 { width: percentage(5/12); }
			.col-xl-4 { width: percentage(4/12); }
			.col-xl-3 { width: percentage(3/12); }
			.col-xl-2 { width: percentage(2/12); }
			.col-xl-1 { width: percentage(1/12); }
		}		
	}


	// ==================================================
	// BLOCKS
	// ==================================================

	.directory_page .content {

		.welcome { order: 1; }
		.main { order: 2; }
		.sidebar { order: 3; }
		@include media-breakpoint-up(lg) {
			.row { position: relative; }
			.welcome { @include make-col(9); padding-right: 30px; }
			.main { @include make-col(9); padding-right: 30px; }
			.sidebar {
				@include make-col(3);
				position: absolute;
				top: 0; right: 0;
			}
		}
		@include media-breakpoint-up(xl) {
			.welcome { @include make-col(9); }
			.main { @include make-col(9); }
			.sidebar { @include make-col(3); }
		}

		&.--has-featured {
			.welcome { order: 1; }
			.sidebar { order: 2; }
			.main { order: 3; }

			@include media-breakpoint-up(md) {
				.listing_wrapper {
					position: relative;
					@include make-flex;
				}
					.widget_register { width: 100%; }
					.list-listings { width: 70%; }
					.page_directory_menu { width: 28%; margin-left: auto; }
			}

			@include media-breakpoint-up(lg) {
				.welcome { margin-left: auto; }
				.main { margin-left: auto; }
				.sidebar { right: auto; left: 0; }
				.listing_wrapper {
					@include make-flex;
				}
					.widget_register { width: 70%; }
					.page_directory_menu {
						position: absolute;
						top: 0; right: 0;
						width: 190px;
					}
			}

			@include media-breakpoint-up(xl) {
				.page_directory_menu {
					width: 230px;
				}
			}

		}
		
	}

	body.page_front .content {
		.welcome { order: 1; }
		.main { order: 3; }
		.sidebar { order: 2; }

		@include media-breakpoint-up(lg) {
			.row { position: relative; }
			.welcome { @include make-col(7); padding-right: 30px; }
			.main { @include make-col(7); padding-right: 30px; }
			.sidebar {
				@include make-col(5);
				position: absolute;
				top: 0; right: 0;
			}
		}
	}

	body.page .content {
		@include media-breakpoint-down(md) {
			.sidebar { padding-top: 15px; }
		}
		@include media-breakpoint-up(lg) {
			.main { @include make-col(8); }
			.sidebar { @include make-col(4); }
		}
/* 		@include media-breakpoint-up(xl) {
	.main { @include make-col(7); }
	.sidebar { @include make-col(5); }
} */
	}
