.featured_wrapper {
	@include media-breakpoint-down(md) {
		@include make-flex;
		flex-wrap: wrap;
		justify-content: center;
		> div {
			width: 100%;
		}
	}
	@include media-breakpoint-only(md) {
		> div {
			width: 49%;
			&:nth-child(even) { margin-left: auto; }
		}
	}
}


.abox_wrapper {
	@include make-flex;
	justify-content: space-evenly;
	padding: 0;
	@include media-breakpoint-down(sm) {
		.abox { width: 48%; }
	}
	@include media-breakpoint-only(md) {
		.abox { width: 32%; }
	}
	.page_front & {
		@include media-breakpoint-only(md) {
			.abox:last-child { display: none; }
		}
		@include media-breakpoint-up(lg) {
			.abox { width: 48%; }
		}
	}
}


.abox {
	margin: 0 0 2%;
	padding: 15px;
	@include font-size(14);
	line-height: 1em;
	text-align: center;
	border: 1px solid darken($body-bg, 7.5%);
	background-color: $white;
	img {
		display: block;
		width: auto;
		max-height: 100px;
		margin: 0 auto;
	}
	.abox-title {
		display: block;
		//margin: 10px 0 5px;
		margin: 5px 0;
		//padding-top: 10px;
		@include font-size(16);
		line-height: 1em;
		letter-spacing: -0.0275em;
		//border-top: 1px dotted #ccc;
		small { display: block; }
	}
	.phone {
		@include font-size(17);
		line-height: 1em;
		font-weight: $body-weight-bolder;
		letter-spacing: -0.0425em;
		color: theme(secondary, base) !important;
	}
	p {
		margin-top: 10px;
		line-height: 1.275em;
	}
	> *:last-child {
		margin-bottom: 0 !important;
	}

}

.afeat_wrapper {
	margin-bottom: 20px;
	> .afeat:not(:last-child) { margin-bottom: 20px; }
}

.afeat {
	padding: 20px;
	text-align: center;
	line-height: 1.1;
	letter-spacing: -0.0325em;
	border: 1px solid #cccccc;
	background-color: white;
	hr {
		margin: 20px 0;
		background-color: transparent;
		background-image: linear-gradient(to right, transparent, rgba(black, 0.3) 15%, rgba(black, 0.3) 85%, transparent);
	}

	a:not(.btn) {
		color: $color-secondary;
	}

	> *:first-child { margin-top: 0; }
	> *:last-child { margin-bottom: 0; }

}

	.afeat-products {
		@include make-flex;
		flex-wrap: nowrap;
		justify-content: space-around;
		.afeat-product img { max-width: 120px; }
		@include media-breakpoint-only(md) { .afeat-product img { max-width: 220px; } }
		@include media-breakpoint-only(lg) { .afeat-product img { max-width: 140px; } }
		@include media-breakpoint-up(xl) { .afeat-product img { max-width: 175px; } }
	}

	.afeat-product {
		display: inline-block;
		color: $color-text !important;
		img {
			width: 100%;
			margin: 0 auto;
		}
		small {
			display: block;
		}
	}

	.afeat-logo {
		display: block;
		width: 100%;
		max-width: 360px;
		margin: 0 auto 15px;
	}